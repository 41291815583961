@import "bootstrap/scss/bootstrap";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.modal {
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
}

// aplication styles
.fs-small {
    font-size: small;
}

.fs-smaller {
    font-size: smaller;
}

.px-5px {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.fixed-top-right {
    position: fixed;
    right: 1rem;
    top: 1.4rem;
}

div.dropdown.lang-picker {
    position: absolute;
    right: 0.5rem;
    top: 2.5rem;

    ul.dropdown-menu {
        min-width: fit-content !important;
        width: fit-content !important;
    }
}

.fa-arrow-right-from-arc.fa-rotate-by {
    @extend .text-primary;
    position: absolute;
    right: 34px;
    top: 26px;
    --fa-rotate-angle: -25deg;
}

footer>nav.navbar {
    background-color: #e3f2fd;
}

.chat-message-to {
    background-color: #e3f2fd;
    border-radius: 10px;
    border-top-left-radius: 0%;
    padding: 5px;
    margin: 5px;
}

.chat-message-from {
    background-color: hsl(85, 84%, 67%);
    border-radius: 10px;
    border-top-right-radius: 0%;
    padding: 5px;
    margin: 5px;
}

nav.navbar.fixed-bottom>input {
    margin-bottom: 5rem;
}

header>img.img-sigla {
    width: auto;
    height: 45px;
    margin-right: 10px;
}

img.img-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.position-relative {
    position: relative;
}

.position-top-left {
    position: absolute;
    top: 2px;
    left: 6px;
}

a.img-remove-icon {
    @extend .bg-light;
    @extend .p-2;
    @extend .ms-2;
    @extend .rounded;
}

a {
    text-decoration: none;
}

// Custom bootstrap styles
a.nav-link.position-relative {
    width: fit-content !important;
    @extend .px-2;
    @extend .mx-2;
    @extend .py-1;
    @extend .my-1;
}

input[aria-invalid]:invalid {
    @extend .border;
    @extend .border-danger;
}

input[aria-invalid]:invalid:focus~div.invalid-tooltip {
    position: static;
    @extend .d-block;
    @extend .w-100;
    @extend .opacity-75;
    @extend .py-2;

    p {
        @extend .p-0;
        @extend .m-0;
    }
}

textarea[aria-invalid]:required:invalid {
    @extend .border;
    @extend .border-danger;
}

textarea[aria-invalid]:invalid:focus~div.invalid-tooltip {
    position: static;
    @extend .d-block;
    @extend .w-100;
    @extend .opacity-75;
    @extend .py-2;

    p {
        @extend .p-0;
        @extend .m-0;
    }
}

select[aria-invalid]:required:invalid {
    @extend .border;
    @extend .border-danger;
}

select[aria-invalid]:invalid:focus~div.invalid-tooltip {
    position: static;
    @extend .d-block;
    @extend .w-100;
    @extend .opacity-75;
    @extend .py-2;

    p {
        @extend .p-0;
        @extend .m-0;
    }
}

.text-large {
    font-size: large;
}

.text-larger {
    font-size: larger;
}

nav.navbar ul {
    @extend .navbar-nav;
    @extend .mt-2;
    @extend .mt-lg-0;
    flex-direction: row;

    li {
        @extend .nav-item;
        @extend .mx-2;

        a {
            @extend .nav-link;
        }
    }
}

@media screen and (max-width: 992px) {
    nav.navbar div.navbar-collapse ul {
        flex-direction: column;
    }
}

table:not([class]) {
    @extend .table;
    @extend .table-borderless;
}

table>thead>tr {
    @extend .border-bottom;
    font-size: smaller;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="file"]):not([type="range"]):not([type="color"]),
textarea,
select {
    @extend .form-control;
    @extend .rounded-5;
}

input[type="checkbox"] {
    @extend .form-check-input;
}

input[type="radio"] {
    @extend .form-check-input;
}

select {
    @extend .form-select;
}

button {
    @extend .rounded-5;
    @extend .btn;
}

button:not([class]) {
    @extend .btn-primary;
}

form>fieldset {
    @extend .mb-3;
    display: block;
}

form *:not(div.form-floating) label {
    @extend .form-label;
    @extend .mt-3;
}

form *:not(div.form-floating) label:not([class]) {
    @extend .text-muted;
}

form>label {
    display: block;
}

form>button:not(:last-child) {
    @extend .me-3;
}