div.upload-dropzone {
    border: 1px dotted grey;
    position: relative;
    min-height: 5rem;

    .multiple {
        min-height: 10rem;
    }
}

div.upload-dropzone>i {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    opacity: 0.8;
}

div.upload-dropzone>label {
    top: 0;
    right: 0;
    position: absolute;
    opacity: 0.8;
}

input[type="file"] {
    width: 0;
    height: 0;
    overflow: hidden;
}